<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="4">
        <el-button
          type="primary"
          @click="showAddRole"
          v-auth="this.per.ROLE_ADD"
        >
          添加
        </el-button>
      </el-col>
    </el-row>

    <el-table class="page-top-space" border :data="dataSource">
      <el-table-column prop="roleName" label="角色名称"></el-table-column>
      <el-table-column prop="description" label="描述"></el-table-column>
      <el-table-column prop="operation" label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            v-auth="this.per.ROLE_PERMISSION_UPDATA"
            @click="showEditPermission(scope.row)"
            >编辑</span
          >
          <span
            class="option option-danger"
            @click="showDel(scope.row.id)"
            v-auth="this.per.ROLE_DEL"
            >删除</span
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="page-top-space"
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page.sync="pages.pageIndex"
      @current-change="getList"
    >
    </el-pagination>

    <EditRoleModalPermission
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_ROLE_PERMISSION_MODAL"
      @submit="submitPermission"
    >
    </EditRoleModalPermission>
  </div>
</template>

<script>
import {
  fetchRoleList,
  updateRole,
  delRole,
  addRole,
  updateRolePermission,
  fetchRolePermission,
} from "@/api/permission";
import EditRoleModalPermission from "./component/EditRoleModalPermission";

export default {
  data() {
    return {
      MODAL_KEY: {
        EDIT_ROLE_MODAL: "EDIT_ROLE_MODAL",
        EDIT_ROLE_PERMISSION_MODAL: "EDIT_ROLE_PERMISSION_MODAL",
      },
      treeData: [],
      modalType: "",
      query: {
        roleName: "",
        description: "",
      },
      pages: {
        pageIndex: 1,
        size: 15,
      },
      total: 0,
      modalData: {},
      modalType: '',
      dataSource: [],
    };
  },
  components: {
    EditRoleModalPermission: EditRoleModalPermission,
  },

  mounted() {
    this.getList();
  },

  methods: {
    showAddRole() {
      this.modalData = {};
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_ROLE_MODAL].showModal();
    },

    showDel(id) {
      this.$confirm("确认要删除吗", "提示", { type: "warning" }).then((res) => {
        this.doDel(id);
      });
    },

    doDel(id) {
      delRole({ id }).then((res) => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.validateCurrentDataLen([id], this.dataSource, this);
        this.getList();
      });
    },

    showEditPermission(data) {
      this.modalData = this.deepClone(data);
      this.modalType = "edit";
      fetchRolePermission({ roleId: data.id }).then((res) => {
        this.modalType = "edit";
        this.$refs[this.MODAL_KEY.EDIT_ROLE_PERMISSION_MODAL].showModal(res.data.data);
      });
    },

    submitPermission(data) {
     
      updateRolePermission(data).then((res) => {
        this.$message.success("修改成功");
        this.$refs[this.MODAL_KEY.EDIT_ROLE_PERMISSION_MODAL].closeModal();
        this.getList();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.query.page = current;
      }

      fetchRoleList({ query: this.query, ...this.pages }).then((res) => {
        this.dataSource = res.data.data.records || [];
        this.total = res.data.data.total;
      });
    },

    operationRole(params) {
      let result = addRole;
      let msg = "添加成功";
      if (!this.validatenull(params.authority_id)) {
        msg = "编辑成功";
        result = updateRole;
      }

      result(params).then((res) => {
        this.$message({
          message: msg,
          type: "success",
        });
        this.$refs[this.MODAL_KEY.EDIT_ROLE_MODAL].closeModal();
        this.getList();
      });
    },
  },
};
</script>

<style scoped>
</style>
