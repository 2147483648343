import request from '@/common/axios';

//获取角色列表
export function fetchRoleList(data) {
    return request({
        url: '/web/admin/role/rolePage',
        method: 'POST',
        data: data,
    });
}


//获取角色列表
export function fetchRoleListAll(data) {
    return request({
        url: '/web/admin/role/rolePage',
        method: 'POST',
        data: {
            "pageIndex": 1,
            "pageSize": 999,
        }
    });
}

//删除角色
export function delRole(data) {
    return request({
        url: '/web/admin/role/deleteRole',
        method: 'POST',
        data: data,
    });
}

//添加角色
export function addRole(data) {
    return request({
        url: '/web/admin/role/addRole',
        method: 'POST',
        data: data,
    });
}

//修改角色
export function updateRole(data) {
    return request({
        url: '/web/admin/role/updateRole',
        method: 'POST',
        data: data,
    });
}

// 查询角色权限
export function fetchRolePermission(data) {
    return request({
        url: '/web/admin/role/getPermissionTreeByRole?roleId='+data.roleId,
        method: 'Get',
        data: data,
    });
}

// 查询权限菜单树
export function fetchPermissionTree(data) {
    return request({
        url: '/web/admin/role/getAllPermissionTree',
        method: 'GET',
        data: data,
    });
}

// 查询全部权限树
export function fetchAllPermissionTree(data) {
    return request({
        url: '/web/admin/role/getAllPermissionTree',
        method: 'GET',
        data: data,
    });
}

// 查询权限菜单列表
export function fetchPermissionList(data) {
    return request({
        url: '/platform',
        method: 'POST',
        data: {
            "iReqCode": 305,
            "oReqData": data,
        }
    });
}

// 修改权限
export function updatePermission(data) {
    return request({
        url: '/web/admin/role/updatePermission',
        method: 'POST',
        data: data,
    });
}

// 添加权限
export function addPermission(data) {
    return request({
        url: '/web/admin/role/addPermission',
        method: 'POST',
        data: data,
    });
}

// 删除权限
export function delPermission(data) {
    return request({
        url: '/web/admin/role/deletePermission',
        method: 'POST',
        data: data,
    });
}

// 修改角色权限
export function updateRolePermission(data) {
    return request({
        url: '/web/admin/role/updateRole',
        method: 'POST',
        data: data,
    });
}