<template>
  <!-- 新增模态框 -->
  <el-dialog
    v-model="isShow"
    :width="defaultWidth"
    :close-on-click-modal="isClickModalClose"
    @close="closeModal"
    title="修改角色权限"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="角色名称：" prop="roleName">
        <el-input
          v-model="modalData.roleName"
          placeholder="请填写角色名称"
          :maxLength="32"
        ></el-input>
      </el-form-item>

      <el-form-item label="角色描述：" prop="description">
        <el-input
          v-model="modalData.description"
          placeholder="请填写角色描述"
          :maxLength="32"
        ></el-input>
      </el-form-item>
    </el-form>

    <el-tree
      :data="treeData"
      ref="tree"
      node-key="id"
      show-checkbox
      :props="{ label: 'name', children: 'children' }"
    ></el-tree>

    <template #footer>
      <div>
        <el-button @click="closeModal">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { fetchAllPermissionTree } from "@/api/permission";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData",],
  data() {
    return {
      baseData: [], //后台原数据
      resourceArr: [],
      treeData: [],
      curData: [],
      rules: {
        roleName: {
          required: true,
          message: "角色名称不能为空",
          trigger: "blur",
        },
        description: {
          required: true,
          message: "角色描述不能为空",
          trigger: "blur",
        },
      },
      formName: "formRef",
    };
  },

  mounted() {
    this.getUserMenu();
  },
  methods: {
    showModal(data) {
      this.isShow = true;
      this.resourceArr = [];
      this.$refs.tree && this.$refs.tree.setCheckedKeys([]);
      if (!this.validatenull(data)) {
        this.getCurRoleResource(data);
      }
    },

    //获取当前角色的权限
    getCurRoleResource(data) {
      this.resourceArr = [];
      this.resourceArr = this.parseResource(data);
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(this.resourceArr);
      });
    },

    parseResource(data) {
      let ids = [];

      data.map((item) => {
        if (item.children) {
          let tmpIds = this.parseResource(item.children);
          ids.push(...tmpIds);
        } else {
          ids.push(item.id);
        }
      });

      return ids;
    },

    submit() {
      let resultMenu = [];
      resultMenu = this.$refs.tree.getCheckedKeys();
      if (resultMenu.length === 0) {
        this.$message.error("权限不能为空");
        return;
      }
      this.validateForm().then((res) => {
        let obj = {
          id: this.modalData.id,
          permissionIds: resultMenu,
          description: this.modalData.description,
          roleName: this.modalData.roleName
        }
        this.$emit("submit", obj);
      });
    },

    getUserMenu() {
      // 如果当前用户是系统管理员， 查询所有的权限
      fetchAllPermissionTree().then((res) => {
        this.treeData = res.data.data || [];
      });
    },
  },
};
</script>
